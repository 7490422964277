import React from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';

import './style.scss';

function ErrorModal({ title = 'There was an error on the page and a reload is required.' }) {
  return (
    <div className="ErrorModal__Container">
      <div className="ErrorModal__Inner">
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <ErrorIcon style={{ marginRight: '16px', color: '#ff4444' }} />
          <div className="ErrorModal__Title">{title}</div>
        </Box>

        <Box className="ErrorModal__Footer">
          <Button onClick={() => window.location.reload()}>Reload</Button>
        </Box>
      </div>
    </div>
  );
}

export default ErrorModal;
