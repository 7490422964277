import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './helpers/utils';

import ErrorModal from 'components/common/ErrorModal';
import usePubSub from 'utils/usePubSub';
import agent from './agent';

const Main = props => {
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', true));
  const [isTopNav, setIsTopNav] = useState(getItemFromStore('isTopNav', true));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', false)
  );
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState(getItemFromStore('navbarStyle', 'card'));
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);

  const value = {
    isRTL,
    isDark,
    isFluid,
    setIsRTL,
    isTopNav,
    currency,
    setIsDark,
    setIsFluid,
    toggleModal,
    setIsTopNav,
    navbarStyle,
    setCurrency,
    showBurgerMenu,
    setNavbarStyle,
    isOpenSidePanel,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode('isFluid');
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    setItemToStore('isTopNav', isTopNav);
    // eslint-disable-next-line
  }, [isTopNav]);

  useEffect(() => {
    setItemToStore('navbarStyle', navbarStyle);
    // eslint-disable-next-line
  }, [navbarStyle]);

  useEffect(() => {
    let isMounted = true;
    const emailId = localStorage.getItem('user_email');

    if (!emailId) return;

    agent.Training.user_dashboard(emailId)
      .then(res => {

        if (!isMounted) return;

        if (res.data && !res.error) {
          const org = res.data.org;
          const userId = res.data.userId;
          setUserInfo({ org, userId });
        } else {
          setShowErrorModal(true);
          // window.localStorage.clear();
          // window.location = '/login'
        }
      })
      .catch(err => {        
        setShowErrorModal(true);
        window.localStorage.clear();
        window.location = '/login'
      });
  

      return () => {
      isMounted = false;
    };
  }, []);

 

  usePubSub(
    'fatal_error',
    useCallback(() => {
      setShowErrorModal(true);
    }, [])
  );

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ ...value, userInfo }}>
      {props.children}
      {showErrorModal ? <ErrorModal /> : null}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
