import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';

import PubSubProvider from 'utils/usePubSub/Provider';

ReactDOM.render(
  <>
    <PubSubProvider>
      <Main>
        <App />
      </Main>
    </PubSubProvider>
  </>,
  document.getElementById('main')
);
