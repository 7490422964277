import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import AutoLogout from 'AutoLogout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import './styles/index.scss';

const App = () => {
  let loggedIn = window.localStorage.getItem('jwt');

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout loggedIn={loggedIn}/>
      <AutoLogout />
    </Router>
  );
};

export default App;
