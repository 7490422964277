import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const INTERVAL_DURATION = 60 * 1000; // We check every 60 seconds
const MAX_IDLE_TIME_MINUTES = 30;

function AutoLogout() {
  const location = useLocation();
  const locationRef = useRef(location);
  locationRef.current = location;

  const intervalRef = useRef(null);
  const idleTimeRef = useRef(0);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (!window.localStorage.getItem('jwt')) {
        idleTimeRef.current = 0;
        console.log('ignoring');
      } else {
        idleTimeRef.current = idleTimeRef.current + INTERVAL_DURATION;
      }

      if (idleTimeRef.current >= MAX_IDLE_TIME_MINUTES * 60 * 1000) {
        window.location = '/logout';
      }
    }, INTERVAL_DURATION);

    window.addEventListener('mousemove', () => {
      idleTimeRef.current = 0;
    });

    window.addEventListener('keypress', () => {
      idleTimeRef.current = 0;
    });

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  return null;
}

export default AutoLogout;
