import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT_LOCAL = 'https://api.trainings.cyberqoro.seclogic.io/api';
// const API_ROOT_LIVE = 'https://dev-api.trainings.cyberq.seclogic.io:8000/api';
// const API_ROOT = API_ROOT_LIVE
//const encode = encodeURIComponent;
// const API_ROOT = API_ROOT_LOCAL;
const API_ROOT = API_ROOT_LOCAL;


const responseBody = res => res.body;
let token = window.localStorage.getItem('jwt') ? window.localStorage.getItem('jwt') : null;

const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
};
const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
};

const Training = {
  user_dashboard: username => requests.post('/training/user/bucket/', { data: { action: 'dashboard_data', formdata: { username } } }),
  user_bucket: ({org, userId}) => requests.post('/training/user/bucket/', { data: { action: 'list_bucket', formdata: { org, clientId: userId } } }),
  user_bucketdetails: formdata =>
    requests.post('/training/user/bucket/', { data: { action: 'get_one', formdata: formdata } }),
  user_activity: data => requests.post('/training/activity/', { data: { action: 'create_new', formdata: data } }),
  add_completion: data => requests.post('/training/activity/', { data: { action: 'add_completion', formdata: data } }),
  user_quizdetails: data => requests.post('/training/user/bucket/', { data: { action: 'get_quiz', formdata: data } }),
  user_quizsubmit: data => requests.post('/training/user/bucket/', { data: { action: 'submit_quiz', formdata: data } }),
  send_nchat: data => requests.post('/training/notloggedin/', { data: { action: 'send_nchat', formdata: data } }),
  send_chat: data => requests.post('/training/user/bucket/', data)
};

const Auth = {
  user_login: user => requests.post('/users/login/', { user }),
  user_changepass: data => requests.put('/users/changepass/', { data: { formdata: data } }),
  forgotPassword: email => requests.post('/ForgotPassword', { data: { email } }),
  setPasswordRegistration: data => requests.post('/Activate',{data: data}),
  setPassword: (query, password, c_password) =>
    requests.put(`/ForgotPassword`, { data: { query, password, c_password } }),
  co_branding_domain: data => requests.post('/CheckingDomain',{data: data})
};

export default {
  Training,
  Auth,
  setToken: _token => {
    token = _token;
  }
};
