import { createContext } from 'react';

const AppContext = createContext({
  isFluid: false,
  isRTL: false,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'card',
  secretKey:'e7b75a472b65bc4a42e7b3f78833a4d00040beba796062bf7c13d9533b149e5ec3784813dc20348fdf248d28a2982df85b83d1109623bce45f08238f6ea9bd9bb5f406427b2a40f969802635b8907a0a57944f2c12f334bd081d5143a357c173a611e1b64a'
});


export const ClientContext = createContext({ client: {} });

export const CampaignContext = createContext({ campaign: {} });

export const AssessmentContext = createContext({ assessment: {} });

export const TrainingContext = createContext({ training: {} });



export default AppContext;
